
import { VTableHeaders } from "@/types";
import { Component } from "vue-property-decorator";
import RegistrationList from "./RegistrationList.vue";

@Component({})
export default class RepresentationList extends RegistrationList {
  get headers(): VTableHeaders[] {
    return [
      //   {
      //     text: this.$t("gema.table.header.applicationNumber").toString(),
      //     value: "registration.id",
      //     width: 200,
      //   },
      {
        text: this.$t("gema.table.header.membersMemberNumber").toString(),
        value: "member.memberNumber", // ??
        width: 165,
      },
      {
        text: this.$t("gema.table.header.registrationMemberName").toString(),
        value: "memberName",
        width: 200,
      },
      {
        text: this.$t("gema.table.header.repMemberNumber").toString(),
        value: "representativeId", // ??
        width: 165,
      },
      {
        text: this.$t("gema.table.header.representativeName").toString(),
        value: "representativeName", // ??
        width: 275,
      },
      {
        text: this.$t("gema.table.header.attendanceType").toString(),
        value: "attendanceType",
        width: 200,
      },
      {
        text: this.$t("gema.table.header.dayOfParticipation").toString(),
        value: "attendanceDays",
        width: 200,
      },
      {
        text: this.$t("gema.table.header.registrationStatus").toString(),
        value: "registrationStatus", // ??
        width: 200,
      },
      {
        text: this.$t("gema.table.header.memberStatus").toString(),
        value: "member.group",
        width: 136,
      },
      {
        text: this.$t("gema.table.header.group").toString(),
        value: "member.memberStatus",
        width: 152,
      },
      {
        text: this.$t("gema.table.header.created").toString(),
        value: "createdTimestamp", // ??
        width: 270,
      },
      {
        text: this.$t("gema.table.header.lastEdited").toString(),
        value: "lastEditedTimestamp", // ??
        width: 270,
      },
      {
        text: this.$t("dataTable.actions").toString(),
        value: "actions", // ??
        width: 110,
      },
    ];
  }

  setSortBy(sortBy: string | undefined): void {
    const sortByV = sortBy ? sortBy : "";
    this.sortBy = sortByV;

    this.sortByParamV = "";
    switch (sortByV) {
      case "createdTimestamp":
      case "lastEditedTimestamp":
      case "member.memberNumber":
        this.sortByParamV = sortByV;
        break;
      case "memberName":
        this.sortByParamV = "name";
        break;
      case "registrationStatus":
        this.sortByParamV = "status";
        break;
      case "attendanceDays":
        this.sortByParamV = "days";
        break;
      case "representativeName":
        this.sortBy = "representativeName";
        break;
      default:
        this.sortBy = "id";
    }
  }
}
