














import { Vue, Component, Watch } from "vue-property-decorator";
import { RouteName } from "@/router/types";
import PageTemplate from "@/components/PageTemplate.vue";

import RegistrationList from "@/components/Registrations/RegistrationList.vue";
import RepresentationList from "@/components/Registrations/RepresentationList.vue";
import { UserListViews } from "@/components/Registrations/@types";

@Component({
  components: {
    PageTemplate,
    RegistrationList,
    RepresentationList,
  },
})
export default class Registrations extends Vue {
  RouteName = RouteName;
  UserListViews = UserListViews;
  isTopLevelRoute = true;

  created() {
    this.routeChange();
  }

  @Watch("$route")
  routeChange() {
    this.isTopLevelRoute =
      this.$router.currentRoute?.name === RouteName.Registrations || false;
  }

  get isRepresentationView(): boolean {
    return [
      UserListViews.digitalRightsTransfer,
      UserListViews.presentRightsTransfer,
      UserListViews.digitalSubstitution,
      UserListViews.presentSubstitution,
    ].includes(this.$route.query.view as UserListViews);
  }
}
