

















































































































































































































































































































import { RouteName } from "@/router/types";
import { VTableHeaders } from "@/types";
import { Vue, Component, Watch } from "vue-property-decorator";

import RegistrationService, {
  ExportType,
  SearchFilter,
  SearchRegistrationsQueryParams,
} from "./services/RegistrationService";
import { debounce } from "@/helpers";
import {
  attendanceDayLabels,
  AttendanceType,
  attendanceTypeLabels,
  OccupationalGroup,
  RegistrationDay,
  RegistrationResponse,
  RegistrationStatus,
  registrationStatusLabels,
  Representation,
  RepresentationType,
  userListViewLabels,
  UserListViews,
} from "./@types";
import { Registration } from "./models";

@Component({})
export default class RegistrationList extends Vue {
  AttendanceType = AttendanceType;
  RouteName = RouteName;
  OccupationalGroup = OccupationalGroup;
  ExportType = ExportType;

  results: RegistrationResponse[] = [];

  page = 1;
  resultsPerPage = 10;
  totalResults = 50;
  search = "";
  previousSearch = "";

  initialising = true;
  loading = true;
  searching = false;

  defaultView: string = this.tableViewOptions[0][0];
  currentView: string = (this.$route.query.view as string) || this.defaultView;

  sortByParamV = "";
  sortBy = "id";
  sortOrder: boolean | null = null;

  setSortBy(sortBy: string | undefined) {
    const sortByV = sortBy ? sortBy : "";
    this.sortBy = sortByV;
    switch (sortByV) {
      case "member.memberNumber":
      case "member.memberStatus":
      case "attendanceType":
        this.sortByParamV = sortByV;
        break;
      case "member.group":
        this.sortByParamV = "member.`group`";
        break;
      case "memberName":
        this.sortByParamV = "name";
        break;
      case "registrationStatus":
        this.sortByParamV = "status";
        break;
      case "attendanceDays":
        this.sortByParamV = "days";
        break;
      default:
        this.sortBy = "id";
        this.sortByParamV = "";
    }
  }

  setSortOrder(order: boolean) {
    if (order === undefined || order === null) {
      this.sortOrder = null;
    } else {
      this.sortOrder = order;
    }
  }

  async created() {
    this.currentView =
      (this.$route.query?.view as string) || this.tableViewOptions[0][0];

    // Initialise search params
    this.applyUrlParams();

    this.$nextTick(() => {
      this.initialising = false;
      this.getSearchResults();
    });
  }

  get headers(): VTableHeaders[] {
    return [
      {
        text: this.$t("gema.table.header.memberNumber").toString(),
        value: "member.memberNumber",
        width: 162,
      },
      {
        text: this.$t("gema.table.header.name").toString(),
        value: "memberName",
        // sortable: false,
        width: 200,
      },
      {
        text: this.$t("gema.table.header.dayOfParticipation").toString(),
        value: "attendanceDays",
        width: 200,
      },
      {
        text: this.$t("gema.table.header.attendanceType").toString(),
        value: "attendanceType",
        width: 200,
      },
      {
        text: this.$t("gema.table.header.registrationStatus").toString(),
        value: "registrationStatus",
        width: 185,
      },
      {
        text: this.$t("gema.table.header.memberStatus").toString(),
        value: "member.group",
        width: 136,
      },
      {
        text: this.$t("gema.table.header.group").toString(),
        value: "member.memberStatus",
        width: 152,
      },
      {
        text: this.$t("polls.actions").toString(),
        value: "actions",
        width: 110,
      },
    ];
  }

  get tableViewOptions(): [string, string][] {
    return Object.entries(userListViewLabels());
  }

  // TODO: Decide on sortBy options, add translated labels and shorthand url params
  get sortByOptions(): string[] {
    return [
      "Default",
      "birthDate", //
      "email", //
      "fullName", //
    ];
  }

  get inactiveClearTextStyles() {
    return {
      opacity: 0,
      "pointer-events": "none",
    };
  }

  get attendanceTypeLabels() {
    return attendanceTypeLabels();
  }

  get registrationStatusLabels() {
    return registrationStatusLabels();
  }

  get attendanceDayLabels() {
    return attendanceDayLabels();
  }

  getAttendanceTypeLabel(item: AttendanceType | null): string {
    let label = "";
    if (item) {
      label = this.attendanceTypeLabels[item];
    }
    return label || "TBD";
  }

  getRegistrationStatusLabel(item: RegistrationStatus | null): string {
    let label = "";
    if (item) {
      label = this.registrationStatusLabels[item];
    }
    return label || "~";
  }

  getRegistrationDayLabel(item: RegistrationDay | null): string {
    let label = "";
    if (item) {
      label = this.attendanceDayLabels[item];
    }
    return label || "TBD";
  }

  getRepresentativeId(representation: Representation | null): string {
    if (
      representation &&
      representation.representativeMemberId &&
      !representation.representativeMemberId?.startsWith("nmr:")
    ) {
      return representation.representativeMemberId;
    }

    return "N/A";
  }

  // Search

  debouncedSearch = debounce(() => this.getSearchResults(), 450);

  get viewFilters(): SearchFilter[] {
    const filters: SearchFilter[] = [];

    const filterGroup: OccupationalGroup | null = ((view: string) => {
      if (
        [
          UserListViews.curiaConsultationHourLyricist.toString(),
          UserListViews.occupationalGroupGatheringLyricist.toString(),
        ].includes(view)
      ) {
        return OccupationalGroup.T;
      }

      if (
        [
          UserListViews.curiaConsultationHourComposer.toString(),
          UserListViews.occupationalGroupGatheringComposer.toString(),
        ].includes(view)
      ) {
        return OccupationalGroup.K;
      }

      if (
        [
          UserListViews.curiaConsultationHourPublisher.toString(),
          UserListViews.occupationalGroupGatheringPublisher.toString(),
        ].includes(view)
      ) {
        return OccupationalGroup.V;
      }

      return null;
    })(this.currentView);

    switch (this.currentView) {
      case UserListViews.attendance:
        filters.push({
          key: "attendanceType",
          value: AttendanceType.IN_PERSON.toString(),
        });
        break;
      case UserListViews.preVoting:
        filters.push({
          key: "attendanceType",
          value: AttendanceType.PRE_VOTE.toString(),
        });
        break;
      case UserListViews.digitalParticipation:
        filters.push({
          key: "attendanceType",
          value: AttendanceType.DIGITAL.toString(),
        });
        break;
      case UserListViews.presentRightsTransfer:
        filters.push({
          key: "representation.type",
          value: RepresentationType.EX_IN_PERSON.toString(),
        });
        break;
      case UserListViews.digitalRightsTransfer:
        filters.push({
          key: "representation.type",
          value: RepresentationType.EX_DIGITAL.toString(),
        });
        break;
      case UserListViews.presentSubstitution:
        filters.push({
          key: "representation.type",
          value: RepresentationType.REP_IN_PERSON.toString(),
        });
        break;
      case UserListViews.digitalSubstitution:
        filters.push({
          key: "representation.type",
          value: RepresentationType.REP_DIGITAL.toString(),
        });
        break;
      case UserListViews.memberFestival:
        filters.push({
          key: "member.sideEventAttendance.memberFestival",
          value: "TRUE",
          fieldType: "BOOLEAN",
        });
        break;
      case UserListViews.roadshow:
        filters.push({
          key: "member.sideEventAttendance.roadshow",
          value: "TRUE",
          fieldType: "BOOLEAN",
        });
        break;
      case UserListViews.technicalTraining:
        filters.push({
          key: "member.sideEventAttendance.technicalTraining",
          value: "TRUE",
          fieldType: "BOOLEAN",
        });
        break;
      case UserListViews.curiaConsultationHourLyricist:
      case UserListViews.curiaConsultationHourComposer:
      case UserListViews.curiaConsultationHourPublisher:
        filters.push({
          key: "member.`group`",
          value: (filterGroup as OccupationalGroup).toString(),
        });
        filters.push({
          key: "member.sideEventAttendance.curiaConsultationHour",
          value: "TRUE",
          fieldType: "BOOLEAN",
        });
        break;
      case UserListViews.occupationalGroupGatheringLyricist:
      case UserListViews.occupationalGroupGatheringComposer:
      case UserListViews.occupationalGroupGatheringPublisher:
        filters.push({
          key: "member.`group`",
          value: (filterGroup as OccupationalGroup).toString(),
        });
        filters.push({
          key: "member.sideEventAttendance.occupationalGroupGathering",
          value: "TRUE",
          fieldType: "BOOLEAN",
        });
        break;
    }

    return filters;
  }

  async getSearchResults() {
    this.searching = true;
    this.loading = true;
    // this.results = [];

    if (this.params.query !== this.previousSearch && this.params.page > 1) {
      this.page = 1;
      return;
    } else {
      this.previousSearch = this.params.query;
    }

    try {
      const res =
        await RegistrationService.searchRegistrations<RegistrationResponse>({
          page: this.page,
          resultsPerPage: this.params.perPage,
          searchQuery: this.params.query,
          filters: [...this.viewFilters],
          sortBy: this.sortByParamV,
          order: this.params.order,
        });

      this.results = res.results;
      this.totalResults = res.totalCount;
    } catch (error) {
      console.log(error);
    }

    this.loading = false;
    this.searching = false;
  }

  get params() {
    return {
      page: this.page,
      perPage: this.resultsPerPage,
      query: this.search ? this.search.trim() : "",
      view:
        this.currentView !== this.defaultView ? this.currentView : undefined,
      sort: this.sortBy,
      order:
        this.sortOrder !== null
          ? this.sortOrder
            ? ("DESC" as const)
            : ("ASC" as const)
          : undefined,
    };
  }

  @Watch("params", { deep: true })
  async onParamChanged() {
    if (this.initialising) return;

    this.loading = true;
    this.debouncedSearch();
    this.updateUrlParams();
  }

  updateUrlParams(): void {
    const { query } = this.$route.query;

    const queryArgs = {
      view:
        this.defaultView !== this.currentView ? this.currentView : undefined,
      s: query || this.params.query || undefined,
      sort:
        this.params.sort && this.params.sort !== "id"
          ? this.params.sort
          : undefined,
      order:
        this.params.sort !== "id" && this.params.order
          ? this.params.order
          : undefined,
      page: this.page > 1 ? this.page.toString() : undefined,
      perPage:
        this.resultsPerPage !== 10 ? this.resultsPerPage.toString() : undefined,
    };

    if (JSON.stringify(queryArgs) !== JSON.stringify(this.$route.query)) {
      this.$router.replace({
        name: RouteName.Registrations,
        query: queryArgs,
      });
    }
  }

  applyUrlParams(): void {
    if (this.$route.query.s) {
      this.search = this.$route.query.s as string;
    }

    if (this.$route.query.sort) {
      this.sortBy = this.$route.query.sort as string;
      this.setSortBy(this.$route.query.sort as string);
    }

    if (this.$route.query.order) {
      this.sortOrder = this.$route.query.order === "DESC" ? true : false;
    }

    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page as string);
    }

    if (this.$route.query.perPage) {
      this.resultsPerPage = parseInt(this.$route.query.perPage as string);
    }

    if (this.$route.query.query) {
      this.search = this.$route.query.query as string;
    }

    this.updateUrlParams();
  }

  // Exporting
  exportCsv(type: ExportType) {
    let query: SearchRegistrationsQueryParams;

    switch (type) {
      case ExportType.ALL:
        query = {
          page: 0,
          resultsPerPage: 999999,
          searchQuery: this.params.query,
          filters: [],
          sortBy: this.params.sort,
          order: this.params.order,
        };
        break;
      case ExportType.QUERY:
        query = {
          page: 0,
          resultsPerPage: 999999,
          searchQuery: this.params.query,
          filters: [...this.viewFilters],
          sortBy: this.params.sort,
          order: this.params.order,
        };
        break;
      case ExportType.PAGE:
        query = {
          page: this.page,
          resultsPerPage: this.params.perPage,
          searchQuery: this.params.query,
          filters: [...this.viewFilters],
          sortBy: this.sortByParamV,
          order: this.params.order,
        };
        break;
      case ExportType.SIDE_EVENTS:
        query = {
          searchQuery: "",
          filters: [],
        };
    }

    RegistrationService.export(query, type);
  }

  // Confirming attendanceTypeSwitches
  confirmDialog = false;
  confirmRegistration: Registration | null = null;
  confirmItem(item: Registration) {
    this.confirmRegistration = item;
    this.confirmDialog = true;
  }

  confirmChange() {
    this.confirmDialog = false;

    if (!this.confirmRegistration) return;
    const help = this.confirmRegistration;
    help.registration.attendanceType = help.registration.attendanceTypePending;
    help.registration.attendanceTypePending = null;
    help.registration.status = RegistrationStatus.CONFIRMED;
    RegistrationService.patch(help).then((res) => {
      console.log(res);
    });

    this.confirmRegistration = null;
  }

  denyChange() {
    this.confirmDialog = false;

    if (!this.confirmRegistration) return;
    const help = this.confirmRegistration;
    // help.registration.attendanceType = help.registration.attendanceTypePending;
    help.registration.attendanceTypePending = null;
    help.registration.status = RegistrationStatus.CONFIRMED;
    RegistrationService.patch(help).then((res) => {
      console.log(res);
    });

    this.confirmRegistration = null;
  }

  memberName(reg: Registration) {
    return reg.member.group === "V"
      ? reg.member.publisherName
      : `${reg.member.firstName} ${reg.member.lastName}` ??
          reg.member.memberNumber;
  }
}
